:root {
    --primary-light: #031847;
    --primary-main: #0c0058;
    --primary-dark: #00081A;
    --secondary-light: #FFDE70;
    --secondary-main: #ff9800;
    --secondary-dark: #FF8000;
    --secondary-contrastText: #ffcc00;
    --secondary-gradient: linear-gradient(332deg, #ff9800, #ff002e);
    --gray-color: #292c3c;
}

/* SATYA */
@import url('https://bootswatch.com/5/materia/bootstrap.min.css');
/* SATYA */

body {
    margin: 0px;
    background: #f8f8f8;
}

.text-error {
    margin-top: -20px;
    color: red !important;
    font-weight: 500;
    font-size: 14px;
}

.main {
    padding: 15px 15px;
    margin-top: 60px;
}

.bg-flare {
    background: #f12711;  /* fallback for old browsers */
    background: -webkit-linear-gradient(332deg, #ff9800, #ff002e);;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(332deg, #ff9800, #ff002e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.bg-red {
    background: linear-gradient(332deg, #ff6186, #d90f00);
}

.bg-green {
    background: linear-gradient(332deg, #009695, #24a129);
}

.bg-blue {
    background: linear-gradient(332deg, #2196f3, #0008ff);
}

.bg-warning {
    background: linear-gradient(332deg, #ff8500, #d2a600);
}

.bg-viol {
    background: linear-gradient(332deg, #9c27b0, #5d0e94);
}
.drawerBox {
    background: white;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}
.drawerMainContent {
    display: flex;
    background: var(--primary-main);
    /* background: var(--gray-color); */
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 25px 10px;
}
.drawerAvaterImg {
    width: 85px;
    height: 85px;
    border-radius: 100%;
    margin-bottom: 10px;
}

/* .MuiDataGrid-cell, 
.MuiDataGrid-row{
    max-height: 35px!important;
    min-height: 35px !important;
} 

.MuiDataGrid-virtualScrollerContent {
    height: 100% !important;
} */
.form-box {
   padding: 5px 0px; 
}
.x-pad {
    padding: 5px 3px;
}

.ListBox {
    height: 100%;
    width: 100%;
    position: absolute;
    margin-top: -15px;
    overflow: scroll;
    background: #fff;
}
.TabContainer {
    background: var(--primary-main);
    margin-top: 56px;
    /* height: 55px; */
}
.NotFound {
    display: block;
    width: 50%;
    margin: auto;
    margin-top: 70px;
}
.SearchBox {
    height: 40px;
    margin: 0px 10px;
    margin-top: 25px;
}
.ChipBox {
    margin: 3px;
    float: left;
}
.login-box {
    padding: 30px;
}
.LoginBox {
    padding: 10px 20px;
}
.LoginImg {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.MuiChip-root MuiChip-filled {
    height: 20px !important;
}
.MuiChip-root MuiChip-label span {
    font-size: 10px !important;
}